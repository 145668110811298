import React from 'react';
import {Images,TextArea,Page} from './index';
import styled from 'styled-components';
import tanezane from '../../asset/img/character-yoshihi.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Comics = () => {
    return(
 <div className="common">
  <H2>タイトル</H2>
  <Images 
  image_1= {tanezane}
  image_2= {tanezane}
   alt_1= "サンプル"
   alt_2= "サンプル"

  />
  <TextArea 
   text="サンプルだよ"
  />
  <Page
   display_pre="display-none"
   display_next=""
   link_pre="/"
   link_next="/"
  />
 </div>
    )
}
export default Comics;