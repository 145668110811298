import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/other/comic5_1.png';
import comic2 from '../../../asset/img/comic/other/comic5_2.png';
import comic3 from '../../../asset/img/comic/other/comic5_3.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story17 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>筑紫ヒロカドとアキタネ</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  image_3= {comic3}
   alt_1= "筑紫ヒロカドとアキタネ"
   alt_2= "筑紫ヒロカドとアキタネ"
   alt_3= "筑紫ヒロカドとアキタネ"

  />
  <TextArea 
   text1="筑紫家は筑後の土着として、大友家と戦ったりしていました。"
   text2="ある時、筑紫家が大友家に勝利した最中、監視役として高橋鏡種が派遣されます。"
   text3="筑紫家・秋月家と反抗的な族を鎮圧するために来た彼は吉と出るか？凶と出るか？"
   data="2018/08-20"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story17;