import React from 'react';
import {CharacterCard} from './index';
import styled from 'styled-components';
import other from '../../asset/img/character-other.png';
import takanobu from '../../asset/img/character-takanobu.png';
import huyuhisa from '../../asset/img/character-huyuhisa.png';
import naoshige from '../../asset/img/character-naoshige.png';

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const ContentsRyuzozi = () => {
    const characterList = [
        {
          img:  takanobu,
          alt: '龍造寺タカノブ',
          name: '龍造寺タカノブ',
          model: '龍造寺隆信',
          master: '小弍家⇨龍造寺家',
          local: '肥前',
          category: '主君',
          text_1: '龍造寺家の当主だよ。',
          text_2: '冷酷で態度が悪いが頭が良い。',
          text_3: 'ドウセツとお母さんが怖いらしい。',
          back: 'character__blue'
         
        },
        {
            img: naoshige,
            alt: '鍋島ナオシゲ',
            name: '鍋島ナオシゲ',
            model: '鍋島直茂',
            master: '龍造寺家',
            local: '肥前',
            category: '忠臣',
            text_1: 'タカノブの忠臣・弟だよ。',
            text_2: '一見爽やかだが二面性があり怖い。',
            text_3: 'たまに主君を叱るよ。',
            back: 'character__blue'
           
          },
          {
            img: other,
            alt: '田尻アキタネ',
            name: '田尻アキタネ',
            model: '田尻鑑種',
            master: '大友家→龍造寺家',
            local: '筑後',
            category: '家臣',
            text_1: 'タカノブの友人だよ。',
            text_2: '武将にしては優しすぎる性格。いい人。',
            text_3: 'たまにタカノブを怖いと思う。',
            back: 'character__blue'
          },
          {
            img: other,
            alt: '龍造寺ケイギンニ',
            name: '龍造寺ケイギンニ',
            model: '龍造寺慶誾尼',
            master: '龍造寺家',
            local: '肥前',
            category: '家族',
            text_1: 'タカノブのお母さんだよ。',
            text_2: 'すごく苦労家で怖く優しい女性。',
            text_3: '怒るとタカノブより怖いよ。',
            back: 'character__blue'
          },
          {
            img: huyuhisa,
            alt: '小弍フユヒサ',
            name: '小弍フユヒサ',
            model: '小弍冬尚',
            master: '小弍家',
            local: '肥前',
            category: '主君',
            text_1: 'タカノブの元主君だよ。',
            text_2: 'ややプライドの高いお坊ちゃん。',
            text_3: '下克上されてしまい立場が無いよ。',
            back: 'character__blue'
          },
          {
            img: other,
            alt: '馬場ヨリチカ',
            name: '馬場ヨリチカ',
            model: '馬場頼周',
            master: '小弍家',
            local: '肥前',
            category: '家臣',
            text_1: '小弍家の家臣。',
            text_2: '龍造寺家にケンカをふっかけたよ。',
            text_3: '結構野心家だったりもする。',
            back: 'character__blue'
          },
          {
            img: other,
            alt: '龍造寺イエカネ',
            name: '龍造寺イエカネ',
            model: '龍造寺家兼',
            master: '少弐家',
            local: '肥前',
            category: '家臣',
            text_1: 'タカノブのおじいちゃん。',
            text_2: '90を超えた戦闘で活躍したスーパージジ。',
            text_3: 'タカノブの才能にいち早く気づいたよ。',
            back: 'character__blue'
          },
          {
            img: other,
            alt: '神代カツトシ',
            name: '神代カツトシ',
            model: '神代勝利',
            master: '少弐家',
            local: '肥前',
            category: '宿敵',
            text_1: 'フユヒサの家臣。タカノブが嫌い。',
            text_2: '龍造寺家と対立しフユヒサに忠誠を誓う。',
            text_3: '原田タカタネとは仲はまあまあいい。',
            back: 'character__blue'
          }
      ]

      

    return(
      <div className="common__item">
      <div className="common__center">
<H3>龍造寺家</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <CharacterCard 
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        master={characterItem.master}
        local={characterItem.local}
        category={characterItem.category}
        text_1={characterItem.text_1}
        text_2={characterItem.text_2}
        text_3={characterItem.text_3}
        />
    )
})}
</Characters>
</div>
</div>
    )
}
export default ContentsRyuzozi;