import React from 'react';
import styled from 'styled-components';

const Footers = styled.div`
text-align: center;
padding-bottom:7em;
`
const Footer = () => {
return(
<Footers>
    <p><small>©️ 2021 kokomono</small></p>
</Footers>
    )
}
export default Footer;