import React from 'react';
import styled from 'styled-components';

const Pages = styled.div`
 display: flex;
 justify-content: space-around;
 width: 100%;
 max-width: 40em;
 margin: 4em auto 0;
 p{
    cursor: pointer;
 }
 a {
    color: #2b2b2b !important;
 }
`;

const Page = (props) => {

    return(
<Pages>
   <p className={props.display_pre}><a href={props.link_pre}>Pre</a></p>
   <p className={props.display_next}><a href={props.link_next}>Next</a></p>
</Pages>
    )
}
export default Page;