import React from 'react';
import styled from 'styled-components';

const ABOUT = styled.div`
border-radius: 10px;
    background-color: #fff;
    padding: 2em;
    margin: 0 auto;
    width: 80%;
p {
    text-align: left;
}
`;
const About = () => {
    return(
<div className="common__contents">
<h2>About</h2>
<ABOUT className="reveal">
<p><strong>ココモノ</strong> は、日本中世史を主軸に活躍した武将・大名をテーマに創作活動をしています。</p>
<p>取り上げている、人物は 九州の旧国名から1人づつピックアップしています。</p>
</ABOUT>
</div>
    )
}
export default About;