import React from 'react';
import {ComicCard} from './index';
import styled from 'styled-components';
import story1 from '../../asset/img/comic/story_otomo-1.png';
import story2 from '../../asset/img/comic/story_otomo-2.png';
import story3 from '../../asset/img/comic/story_otomo-3.png';
import story4 from '../../asset/img/comic/story_otomo-4.png';
import story5 from '../../asset/img/comic/story_otomo-5.png';
import story6 from '../../asset/img/comic/story_otomo-6.png';
import story7 from '../../asset/img/comic/story_otomo-7.png';
import story8 from '../../asset/img/comic/story_otomo-8.png';
import story9 from '../../asset/img/comic/story_otomo-9.png';

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const StoryOtomo = () => {
    const characterList = [
        {
          img: story1,
          alt: 'お猿さんと大友ヨシシゲ',
          name: 'お猿さんと大友ヨシシゲ',
          link: '/comic/otomo/story-1'
         
        },
        {
            img: story2,
            alt: '立花ムネシゲとギンチヨ',
            name: '立花ムネシゲとギンチヨ',
            link: '/comic/otomo/story-2'
          },
          {
            img: story3,
            alt: '立花ムネシゲとヒロカド',
            name: '立花ムネシゲとヒロカド',
            link: '/comic/otomo/story-3'
          },
          {
            img: story4,
            alt: '大友ヨシシゲとイケメン家臣',
            name: '大友ヨシシゲとイケメン家臣',
            link: '/comic/otomo/story-4'
          },
          {
            img: story5,
            alt: '筑紫ヒロカドの嫁入り',
            name: '筑紫ヒロカドの嫁入り',
            link: '/comic/otomo/story-5'
          },
          {
            img: story6,
            alt: '立花アキトシの悩み',
            name: '立花アキトシの悩み',
            link: '/comic/otomo/story-6'
          },
          {
            img: story7,
            alt: '高橋アキタネの祟り',
            name: '高橋アキタネの祟り',
            link: '/comic/otomo/story-7'
          },
          {
            img: story8,
            alt: '立花ドウセツは漢字が嫌い',
            name: '立花ドウセツは漢字が嫌い',
            link: '/comic/otomo/story-8'
          },
          {
            img: story9,
            alt: 'ソウリンのトキメキと髙橋s',
            name: 'ソウリンのトキメキと高橋s',
            link: '/comic/otomo/story-20'
          },
      ]

      

    return(
      <div className="common__item">
<H3>大友家のモノガタリ</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <ComicCard
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        master={characterItem.master}
        local={characterItem.local}
        category={characterItem.category}
        style={characterItem.style}
        link={characterItem.link}
        />
    )
})}
</Characters>

</div>
    )
}
export default StoryOtomo;