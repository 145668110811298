import React from 'react';
import {CharacterCard} from './index';
import styled from 'styled-components';
import other from '../../asset/img/character-other.png';
import harenobu from '../../asset/img/character-harenobu.png';
import kanetugu from '../../asset/img/character-kanetugu.png';
import yoshihi from '../../asset/img/character-yoshihi.png';
import yoshishuke from '../../asset/img/character-yoshisuke.png';
import tanezane from '../../asset/img/character-tanezane.png';
import takatane from '../../asset/img/character-takatane.png';
import hirokado from '../../asset/img/character-hirokado.png';
import goro from '../../asset/img/character-goro.png';
import hidehisha from '../../asset/img/character-hidehisha.png'

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const ContentsOther = () => {
    const characterList = [
        {
          img: tanezane,
          alt: '秋月タネザネ',
          name: '秋月タネザネ',
          model: '秋月種実',
          master: '秋月家',
          local: '筑後',
          category: '主君',
          style: '',
          text_1: '秋月家の当主、大友家が大嫌いだよ。',
          text_2: '昔、父を殺した人物に復讐を誓っている。',
          text_3: 'アキタネと親子みたいに仲がいい。',
          back: 'character__blue'
        },
        {
            img: yoshishuke,
            alt: '伊東ヨシスケ',
            name: '伊東ヨシスケ',
            model: '伊東義祐',
            master: '伊東家',
            local: '日向',
            category: '主君',
            style: '',
            text_1: '伊東家の当主。',
            text_2: '島津家が大嫌いでよくドンパチしているよ。',
            text_3: '仏教と京都が大好き。',
            back: 'character__blue'
          },
          {
            img: kanetugu,
            alt: '肝付カネツグ',
            name: '肝付カネツグ',
            model: '肝付兼続',
            master: '肝付家',
            local: '大隈',
            category: '主君',
            style: '',
            text_1: '肝付家の当主。',
            text_2: '彼1代で肝付家を大きくした鬼才。',
            text_3: '島津家と不仲でヨシスケと絡んでるよ。',
            back: 'character__blue'
          },
          {
            img: yoshihi,
            alt: '相良ヨシヒ',
            name: '相良ヨシヒ',
            model: '相良義陽',
            master: '相良家',
            local: '肥後',
            category: '主君',
            style: '',
            text_1: '相良家の当主。',
            text_2: '不幸にも強豪に挟まれる苦労人。',
            text_3: 'ソウウンととっても仲が良いよ。',
            back: 'character__blue'
          },
          {
            img: harenobu,
            alt: '有馬ハルノブ',
            name: '有馬ハルノブ',
            model: '有馬晴信',
            master: '有馬家',
            local: '肥前',
            category: '主君',
            style: '',
            text_1: '若き有馬家の当主。',
            text_2: 'タカノブにイジられてかわいそう。',
            text_3: '西洋文化かぶれでキザな若者。',
            back: 'character__blue'
          },
          {
            img: hirokado,
            alt: '筑紫ヒロカド',
            name: '筑紫ヒロカド',
            model: '筑紫広門',
            master: '筑紫家',
            local: '筑後',
            category: '主君',
            style: '',
            text_1: '筑紫家の当主。',
            text_2: '子供の頃からタネザネと仲良し。',
            text_3: '妙に変装や手先が器用だよ。',
            back: 'character__blue'
          },
          {
            img: other,
            alt: '甲斐ソウウン',
            name: '甲斐ソウウン',
            model: '甲斐宗運',
            master: '阿蘇家',
            local: '肥後',
            category: '家臣',
            style: '',
            text_1: '阿蘇家の忠臣。',
            text_2: '「九州3強」のコワモテ男子。',
            text_3: 'ヨシヒととても仲良し。',
            back: 'character__blue'
          },
          {
            img: takatane,
            alt: '原田タカタネ',
            name: '原田タカタネ',
            model: '原田隆種',
            master: '原田家',
            local: '筑後',
            style: '',
            category: '主君',
            text_1: '大蔵氏族の中でも特に偉い人。',
            text_2: '大内家と仲良し。大友家が嫌い。',
            text_3: 'よく秋月・筑紫と連んでいるよ。',
            back: 'character__blue'
          },
          // {
          //   img: goro,
          //   alt: 'ゴロウ',
          //   name: '五郎',
          //   model: '大友五郎義鎮',
          //   master: '三原商人',
          //   local: '筑前',
          //   style: 'brah',
          //   category: '南蛮商人',
          //   text_1: '"三原商団"唯一の国内人。',
          //   text_2: '各国の勢力者に取引を持ちかけてるよ。',
          //   text_3: '打算的だけどカリスマ性が高い',
          //   back: 'character__blue'
          // },
          // {
          //   img: other,
          //   alt: 'Promesa',
          //   name: 'Promesa',
          //   model: '',
          //   master: '三原商人',
          //   local: '西班牙',
          //   style: '',
          //   category: '南蛮商人',
          //   text_1: '"三原商団"の商人。',
          //   text_2: '西班牙からやって来た。',
          //   text_3: 'やや好色だけど人懐っこい。',
          //   back: 'character__blue'
          // },
          // {
          //   img: other,
          //   alt: 'Belofte',
          //   name: 'Belofte',
          //   model: '',
          //   master: '三原商人',
          //   local: '和蘭陀',
          //   style: '',
          //   category: '南蛮商人',
          //   text_1: '"三原商団"のリーダー。',
          //   text_2: '和蘭陀からやって来た。',
          //   text_3: 'やや人を見下しがち。',
          //   back: 'character__blue'
          // },
          {
            img: hidehisha,
            alt: '仙石ヒデヒサ',
            name: '仙石ヒデヒサ',
            model: '仙石秀久',
            master: '豊臣→徳川',
            local: '美濃',
            style: '',
            category: '忠臣・主君',
            text_1: '"織田・豊臣・徳川家"に使えた武将。',
            text_2: '豊臣家で大活躍したが大失敗。',
            text_3: '人心掌握に優れ、大名に成り上がった。',
            back: 'character__blue'
          }
      ]

      

    return(
      <div className="common__item">
      <div className="common__center">
<H3>その他</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <CharacterCard 
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        master={characterItem.master}
        local={characterItem.local}
        category={characterItem.category}
        style={characterItem.style}
        text_1={characterItem.text_1}
        text_2={characterItem.text_2}
        text_3={characterItem.text_3}
        />
    )
})}
</Characters>
</div>
</div>
    )
}
export default ContentsOther;