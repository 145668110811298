import React from 'react';

const Main = () => {
    return(
<div>
    <main>

    </main>
</div>
    )
}
export default Main;