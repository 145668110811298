import React from 'react';
import ReactDOM from 'react-dom';
import './asset/style.css';
import './asset/js/common.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Footer,Links,Brand,Sns,TopBtn} from './components/Common/index';

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <Links /> */}
    <Brand />
    <Sns />
    <TopBtn />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
