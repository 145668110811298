import React from 'react';
import styled from 'styled-components';

const Items = styled.div`
 padding: 10em 0;
`;

const About = () => {
    return(
        <div className="common__item">
        <div className="common__center">
        <Items>
<h2>About</h2>
    <p>ココモノ は、日本中世史を主軸に活躍した武将・大名をテーマに創作活動をしています。<br/>取り上げている、人物は 九州の旧国名から1人づつピックアップしています。</p>
    <p>創作活動として <strong>コミック・動画</strong> などをしております。</p>
    </Items>
</div>
</div>
    )
}
export default About;


