import React from 'react';
import {CharacterCard} from './index';
import styled from 'styled-components';
import other from '../../asset/img/character-other.png';
import akitane from '../../asset/img/character-akitane.png';
import shizutane from '../../asset/img/character-shigetane.png';
import yoshishige from '../../asset/img/character-yoshishige.png';
import nagamune from '../../asset/img/character-nagamune.png';
import dousetsu from '../../asset/img/character-dousetsu.png';
import yoshitake from '../../asset/img/character-yoshitake.png';
import akimoto from '../../asset/img/character-akimoto.png';
import soukan from '../../asset/img/character-soukan.png';
import akimasa from '../../asset/img/character-akimasa.png';
import kashinAkitane from '../../asset/img/character-akitane-kashin.png';
import kashinAkimoto from '../../asset/img/character-obara-kashin.png';
import akitoshi from '../../asset/img/character-akitoshi.png';
import muneshige from '../../asset/img/character-muneshige.png';

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const ContentsOtomo = () => {
    const characterList = [
        {
          img: yoshishige,
          alt: '大友ヨシシゲ',
          name: '大友ヨシシゲ',
          model: '大友義鎮',
          master: '大友家',
          local: '豊後',
          category: '主君',
          text_1: '大友家の当主だよ。',
          text_2: '世間知らずのお坊ちゃん。',
          text_3: '"恋愛脳"を除けば優しい当主。',
          back: 'character__blue'
         
        },
        {
          img: nagamune,
          alt: '高橋ナガムネ',
          name: '高橋ナガムネ',
          model: '高橋長宗',
          master: '大友家→毛利家',
          local: '豊後',
          category: '人質',
          text_1: '髙橋アキタネの長男',
          text_2: '謀反により大友家に人質になった。',
          text_3: 'やや気弱の負けず嫌いな男の子。',
          back: 'character__blue'
         
        },
        {
            img: shizutane,
            alt: '高橋シゲタネ',
            name: '高橋シゲタネ',
            model: '高橋鎮種',
            master: '大友家',
            local: '筑後',
            category: '忠臣',
            text_1: 'ヨシシゲの忠臣だよ。',
            text_2: '主君にかなり過保護。ドウセツと仲良し。',
            text_3: '怒るとすごく怖いよ。',
            back: 'character__blue'
           
          },
          {
            img: akimasa,
            alt: '吉弘アキマサ',
            name: '吉弘アキマサ',
            model: '高橋鑑理',
            master: '大友家',
            local: '豊前',
            category: '忠臣',
            text_1: 'ヨシシゲの忠臣だよ。',
            text_2: '「豊後3老」の1人でむちゃ怖いよ。',
            text_3: 'シゲタネのお父さん。ドウセツと仲良し。',
            back: 'character__blue'
           
          },
          {
            img: dousetsu,
            alt: '立花ドウセツ',
            name: '立花ドウセツ',
            model: '立花道雪',
            master: '大友家',
            local: '筑後',
            category: '忠臣',
            text_1: 'ヨシシゲの忠臣だよ。',
            text_2: 'よくヨシシゲに説教をしている。',
            text_3: '「鬼」と恐れられているよ。',
            back: 'character__blue'
          },
          {
            img: soukan,
            alt: '吉岡ソウカン',
            name: '吉岡ソウカン',
            model: '吉岡宗歓',
            master: '大友家',
            local: '豊後',
            category: '忠臣',
            text_1: 'ヨシシゲの家臣だよ。',
            text_2: '「豊後3老」の1人でむちゃ強いよ。',
            text_3: 'ヨシシゲの祖父の代から仕えている。',
            back: 'character__blue'
          },
          {
            img: akitane,
            alt: '高橋アキタネ',
            name: '高橋アキタネ',
            model: '高橋鑑種',
            master: '大友家→毛利家',
            local: '筑後',
            category: '下克上',
            text_1: 'ヨシシゲの多分家臣だよ。',
            text_2: '主君からラブコールを受けてるが嫌い。',
            text_3: 'シゲタネのことがすごく苦手。',
            back: 'character__blue'
          },
          {
            img: kashinAkitane,
            alt: 'アキタネの家臣',
            name: 'アキタネの家臣',
            model: '衛藤尾張守',
            master: '髙橋家',
            local: '筑後',
            category: '家臣',
            text_1: 'アキタネの家臣だよ。',
            text_2: '主君思いの良い家臣。',
            text_3: '実は、アキモトの首級を挙げた。',
            back: 'character__blue'
          },
          {
            img: akitoshi,
            alt: '立花アキトシ',
            name: '立花アキトシ',
            model: '立花鑑載',
            master: '大友家',
            local: '筑後',
            category: '下克上',
            text_1: 'ヨシシゲの多分家臣だよ。',
            text_2: '前に謀反をしたから目をつけられてる。',
            text_3: '正直アキタネをウザがってるよ。',
            back: 'character__blue'
          },
          {
            img: yoshitake,
            alt: '菊池ヨシタケ',
            name: '菊池ヨシタケ',
            model: '菊池義武',
            master: '菊池家',
            local: '肥後',
            category: '下克上',
            text_1: 'ヨシシゲの実叔父だよ。',
            text_2: '肥後菊池家の最後の当主。',
            text_3: '大友家を支配する野望も持ってるよ。',
            back: 'character__blue'
          },
          {
            img: akimoto,
            alt: '小原アキモト',
            name: '小原アキモト',
            model: '小原鑑元',
            master: '大友家',
            local: '肥後',
            category: '下克上',
            text_1: 'ヨシシゲの家臣だよ。',
            text_2: '大友家の爆弾を持っている。',
            text_3: 'ヨシタケ討伐で大活躍したが‥。',
            back: 'character__blue'
          },
          {
            img: kashinAkimoto,
            alt: 'アキモトの家臣',
            name: 'アキモトの家臣',
            model: 'なし',
            master: '小原家',
            local: '肥後',
            category: '家臣',
            text_1: 'アキモトの重臣だよ。',
            text_2: 'やや過保護ぎみで主君を困らせる。',
            text_3: '最後は主君と一緒に亡くなってしまう。',
            back: 'character__blue'
          },
        {
          img: other,
          alt: '蒲池アキモリ',
          name: '蒲池アキモリ',
          model: '蒲池鑑盛',
          master: '大友家',
          local: '筑後',
          category: '家臣',
          text_1: '柳川城の当主で、面倒見がとても良いよ。',
          text_2: '親戚に※田尻アキタネがいる。',
          text_3: '昔、タカノブ達を匿ったりもしてたよ。',
          back: 'character__blue'
        },
        {
          img: other,
          alt: '大友ヨシアキ',
          name: '大友ヨシアキ',
          model: '大友義鑑',
          master: '大友家',
          local: '豊後',
          category: '主君',
          text_1: '大友家の主君で、ヨシシゲの父。',
          text_2: '昔から勉強づけで真面目な人だよ。',
          text_3: '最後に優秀な家臣を多く息子に託した。',
          back: 'character__blue'
        },
        {
          img: other,
          alt: '大友ハルフサ',
          name: '大友ハルフサ',
          model: '大友晴英',
          master: '大友家',
          local: '豊後',
          category: '主君',
          text_1: 'ヨシシゲの兄弟、大内家の当主。',
          text_2: '少し南蛮かぶれのお坊ちゃん。',
          text_3: '家臣に※高橋アキタネを持っていったよ。',
          back: 'character__blue'
        },
        {
          img: muneshige,
          alt: '立花ムネシゲ',
          name: '立花ムネシゲ',
          model: '立花宗茂',
          master: '大友家',
          local: '筑後',
          category: '家臣',
          text_1: 'シゲタネの長男。ヨシシゲの家臣。',
          text_2: '天然な紳士だが‥かなり世間知らず。',
          text_3: '金の管理が苦手。',
          back: 'character__blue'
        },
        {
          img: other,
          alt: '立花ギンチヨ',
          name: '立花ギンチヨ',
          model: '立花誾千代',
          master: '大友家',
          local: '筑後',
          category: '家臣',
          text_1: 'ドウセツの長女。ヨシシゲの家臣。',
          text_2: '男まさりの女の子。',
          text_3: '通常の男性よりも外も中もイケメン。',
          back: 'character__blue'
        },
      ]

      

    return(
      <div className="common__item">
      <div className="common__center">
<H3>大友家</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <CharacterCard 
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        master={characterItem.master}
        category={characterItem.category}
        local={characterItem.local}
        text_1={characterItem.text_1}
        text_2={characterItem.text_2}
        text_3={characterItem.text_3}
        />
    )
})}
</Characters>
</div>
</div>
    )
}
export default ContentsOtomo;