import React from 'react';
import {CharacterCard} from './index';
import styled from 'styled-components';
import yoshihisa from '../../asset/img/character-yoshihisa.png';
import other from '../../asset/img/character-other.png';
import takahisa from '../../asset/img/character-takahisa.png';
import yoshihiro from '../../asset/img/character-yoshihiro.png';
import iehisa from '../../asset/img/character-iehisa.png';

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const ContentsShimazu = () => {
    const characterList = [
        {
          img: yoshihisa,
          alt: '島津ヨシヒサ',
          name: '島津ヨシヒサ',
          model: '島津義久',
          master: '島津家',
          local: '薩摩',
          category: '主君',
          text_1: '島津家の当主だよ。しかし影が薄い。',
          text_2: '優秀な家臣・兄弟に支えられているよ。',
          text_3: '実は、お酒を飲むのが苦手でかわいい。',
          back: 'character__blue'
         
        },
        {
            img: yoshihiro,
            alt: '島津ヨシヒロ',
            name: '島津ヨシヒロ',
            model: '島津義弘',
            master: '島津家',
            local: '薩摩',
            category: '忠臣・主君',
            text_1: 'ヨシヒサの忠臣・弟だよ。',
            text_2: 'よくヨシヒサと間違われてしまうよ。',
            text_3: '実は、数年後に主君になるらしい。',
            back: 'character__blue'
           
          },
          {
            img: iehisa,
            alt: '島津イエヒサ',
            name: '島津イエヒサ',
            model: '島津家久',
            master: '島津家',
            local: '薩摩',
            category: '忠臣',
            text_1: 'ヨシヒサの忠臣・弟だよ。',
            text_2: 'よく戦場に出向く出来る男。',
            text_3: 'しかし、唄がすごく苦手でかわいい。',
            back: 'character__blue'
          },
          {
            img: other,
            alt: '島津トシヒサ',
            name: '島津トシヒサ',
            model: '島津歳久',
            master: '島津家',
            local: '薩摩',
            category: '忠臣',
            text_1: 'ヨシヒサの忠臣・弟だよ。',
            text_2: '戦術で才能を発揮するプロ釣り師。',
            text_3: '兄弟のことが大好き。',
            back: 'character__blue'
          },
          {
            img: takahisa,
            alt: '島津タカヒサ',
            name: '島津タカヒサ',
            model: '島津貴久',
            master: '島津家',
            local: '薩摩',
            category: '主君',
            text_1: '元島津家の当主だよ。',
            text_2: 'よく伊東家とドンパチしてたよ。',
            text_3: '昔カネツグと仲違いしてしまった。',
            back: 'character__blue'
          }
      ]

      

    return(
      <div className="common__item">
      <div className="common__center">
<H3>島津家</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <CharacterCard 
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        master={characterItem.master}
        local={characterItem.local}
        category={characterItem.category}
        text_1={characterItem.text_1}
        text_2={characterItem.text_2}
        text_3={characterItem.text_3}
        />
    )
})}
</Characters>
</div>
</div>
    )
}
export default ContentsShimazu;