import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/shimadu/comic1_1.png';
import comic2 from '../../../asset/img/comic/shimadu/comic1_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story9 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>島津ヨシヒサとヨシヒロ</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "島津ヨシヒサとヨシヒロ"
   alt_2= "島津ヨシヒサとヨシヒロ"
  />
  <TextArea 
   text1="島津義弘をご存知でしょうか？九州戦国史では１番か２番に有名な人物だと思います。"
   text2="「義弘」いわく当時の「島津家」は「義弘」の他に「義久」「歳久」「家久」と兄弟がいたのは有名なお話‥"
   text3="そして‥島津家の「長男」である「義久」が影が非常に薄い。"
   data="2018/06-22"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story9;