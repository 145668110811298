import React from 'react';
import styled from 'styled-components';

const media = {
    sp: '@media(max-width: 650px)'
  }

const BODY = styled.div`
margin: 1em 0.5em auto;
width:100%;
max-width: 18em;
border-radius: 0.5em;
text-align: center;
background-color: #fff;
padding:1em;
border: solid 3px rgb(235, 235, 235);
img {
    width:200px;
	height:200px;
	object-fit:cover;
	border-radius:50%;
}
h3 {
    font-weight:bold;
    font-size: 1.2em;
 }
 div{
    margin: 0.5em 0.5em 1em 0.5em;
    display: flex;
    justify-content: center;
    ${media.sp} {
  -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
   }  
   p {
       margin: 0 0.5em;
       ${media.sp} {
        margin:0.5em;
   } 
   }
 }
`;

const CardContents = styled.article`
padding-top: 1em;
`;

const CardName = styled.h4`
font-weight: bold;
font-size: 1.2em;
`;

const CardTagMaster = styled.p`
background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);
border-radius: 50px;
padding: 0.3em 1em !important;
font-size: 0.8em;
color: #fff;
`;

const CardTagLocal = styled.p`
background-image: linear-gradient( 135deg, #FCCF31 10%, #F55555 100%);
border-radius: 50px;
padding: 0.3em 1em !important;
font-size: 0.8em;
color: #fff;
`;

const CardTagCategory = styled.p`
background-image: linear-gradient( 135deg, #72EDF2 10%, #5151E5 100%);
border-radius: 50px;
padding: 0.3em 1em !important;
font-size: 0.8em;
color: #fff;
`;

const CardText = styled.p`
text-align: left;
padding: 0.1em !important;
font-size: 0.9em;
`;

const CardModel = styled.p`
font-size: 0.85em;
color:#a5a4a4 !important;
padding-top: 0.2em;
`;

const CharacterCardOtomo = (props) => {
    return(
<>
    <BODY className="reveal">
       <img src={props.img} alt={props.alt}/>
       <CardContents>
       <CardName>{props.name}</CardName>
       <CardModel><span className={props.style}>{props.model}</span></CardModel>
       <div>
           <CardTagMaster>{props.master}</CardTagMaster>
           <CardTagCategory>{props.category}</CardTagCategory>
           <CardTagLocal>{props.local}</CardTagLocal>
       </div>
       <CardText>{props.text_1}</CardText>
       <CardText>{props.text_2}</CardText>
       <CardText>{props.text_3}</CardText>
       </CardContents>
    </BODY> 
</>
    )
}
export default CharacterCardOtomo;