import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic2_1.png';
import comic2 from '../../../asset/img/comic/otomo/comic2_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story3 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>立花ムネシゲとヒロカド</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "立花ムネシゲとヒロカド"
   alt_2= "立花ムネシゲとヒロカド"
  />
  <TextArea 
   text1="九州戦国史の後半「筑紫広門」は「島津家」に破れ幽閉されることになりました。"
   text2="その際に島津兵から少し小馬鹿にされていた様で‥"
   text3="その時の内容が「狭門」とのものになります。"
   data="2018/06-22"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story3;