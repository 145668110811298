import React from 'react';
import styled from 'styled-components';

const media = {
    sp: '@media(max-width: 650px)'
  }

const Image = styled.img`
 border-radius: 1em;
 padding: 1em 0 ;
 width: 100%;
 max-width: 30em;
 ${media.sp} {
 width:100%;
 max-width: inherit;
   }
`;

const ImageArea = styled.div`
 padding: 1em 0;
`;

const Images = (props) => {
    return(
<ImageArea>
<p><Image src={props.image_1} alt={props.alt_1} /></p>
<p><Image src={props.image_2} alt={props.alt_2} class={props.style2} /></p>
<p><Image src={props.image_3} alt={props.alt_3} class={props.style3} /></p>
</ImageArea>
    )
}
export default Images;