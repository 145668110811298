import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/other/comic4.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story15 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>大内ヨシナガとクリスマス</H2>
  <Images 
  image_1= {comic1}
  style2= "display-none"
  style3= "display-none"
   alt_1= "大内ヨシナガとクリスマス"

  />
  <TextArea 
   text1="「大内義長」との人物をご存知でしょうか？"
   text2="彼は「大友宗麟」の実弟であり。大内家の当主です。"
   text3="実は彼、日本初のクリスマスを行った人物になります。"
   data="2018/12-25"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story15;