import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/other/comic14_1.png';
import comic2 from '../../../asset/img/comic/other/comic14_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story18 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>秋月タネザネの怒り</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "秋月タネザネの怒り"
   alt_2= "秋月タネザネの怒り"
  />
  <TextArea 
   text1="「秋月種実(タネザネ)」は現在「福岡県」の戦国大名です。彼は幼いときに「大友」との戦で「父と兄」を失いました。"
   text2="未来、その恨みなどから、筑紫・高橋(鑑種)と同じく「反大友家」の中心人物になります。"
   text3="色々あり、高橋(鑑種)と種実は「親子の契り」を交わしていました。"
   data="2018/08-04"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story18;