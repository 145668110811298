import React from 'react';
import './App.scss';
import {Nav} from './components/Common/index';
import { Router, Route, Switch } from 'react-router-dom';
import {Home,Comic,Character,About,Movie,Goods,Comics} from './components/index';
import {Story1,Story2,Story3,Story4,Story5,Story6,Story7,Story8,Story20} from './components/Comics/Otomo/index';
import {Story9} from './components/Comics/Shimadu/index';
import {Story10, Story11, Story12} from './components/Comics/Ryuzozi/index';
import {Story13, Story14, Story15, Story16, Story17, Story18, Story19} from './components/Comics/Other/index';
import {Header} from './components/Common';
import history from './history';
import './style.css';
import Error from "./Error";

function App() {
  return (
      <Router history={history}>
     <div>
     <Header />
     <Nav />
       <Switch>
         <Route exact path='/' component={Home} />
         <Route path='/character' component={Character} />
         <Route path='/about' component={About} />
         <Route path='/movie' component={Movie} />
         {/* <Route path='/comics' component={Comics} /> */}
         <Route path='/goods' component={Goods} />

         <Route path='/comic/otomo/story-1' component={Story1} />
         <Route path='/comic/otomo/story-2' component={Story2} />
         <Route path='/comic/otomo/story-3' component={Story3} />
         <Route path='/comic/otomo/story-4' component={Story4} />
         <Route path='/comic/otomo/story-5' component={Story5} />
         <Route path='/comic/otomo/story-6' component={Story6} />
         <Route path='/comic/otomo/story-7' component={Story7} />
         <Route path='/comic/otomo/story-8' component={Story8} />
         <Route path='/comic/otomo/story-20' component={Story20} />

         <Route path='/comic/shimazu/story-1' component={Story9} />

         <Route path='/comic/ryuzozi/story-1' component={Story10} />
         <Route path='/comic/ryuzozi/story-2' component={Story11} />
         <Route path='/comic/ryuzozi/story-3' component={Story12} />

         <Route path='/comic/other/story-1' component={Story13} />
         <Route path='/comic/other/story-2' component={Story14} />
         <Route path='/comic/other/story-3' component={Story15} />
         <Route path='/comic/other/story-4' component={Story16} />
         <Route path='/comic/other/story-5' component={Story17} />
         <Route path='/comic/other/story-6' component={Story18} />
         <Route path='/comic/other/story-7' component={Story19} />

         <Route path='/comic' component={Comic} />



         <Route component={Error} />
        </Switch>
      </div>
      </Router>
  );
}
export default App;
