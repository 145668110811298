import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/ryuzozi/comic6.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story10 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>龍造寺兄弟</H2>
  <Images 
  image_1= {comic1}
  style2= "display-none"
  style3= "display-none"
   alt_1= "龍造寺兄弟"
  />
  <TextArea 
   text1="「龍造寺隆信」と「鍋島直茂」は父こそは違いますが兄弟になります。"
   text2="のちに、佐賀県の祖となるのが「直茂」‥"
   text3="になるのですが、この兄弟は奥深い。"
   data="2018/03-12"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story10;