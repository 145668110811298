import React from 'react';
import styled from 'styled-components';

const Items = styled.div`
 padding: 10em 0;
`;

const Error = () => {
   
return(
    <div className="common__item">
    <div className="common__center">
<Items>
<h2>404</h2>
 <p>Sorry, the page you're looking con not found.</p>
</Items>
</div>
</div>
    )
}
export default Error;