import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic6_1.png';
import comic2 from '../../../asset/img/comic/otomo/comic6_2.png';
import comic3 from '../../../asset/img/comic/otomo/comic6_3.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story2 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>立花ムネシゲとギンチヨちゃん</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  image_3= {comic3}
   alt_1= "立花ムネシゲとギンチヨちゃん"
   alt_2= "立花ムネシゲとギンチヨちゃん"
   alt_3= "立花ムネシゲとギンチヨちゃん"

  />
  <TextArea 
   text1="戦国夫婦で現在有名な「立花宗茂/誾千代」夫婦です 。"
   text2="ですが、この夫婦‥はあまり良い仲でないことでも有名でした。"
   text3="いつの時代も夫婦関係は難しいようですね‥。同様に主君の「宗麟夫妻」も仲はよくなかったそうです。"
   data="2018/04-12"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story2;