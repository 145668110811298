import React from 'react';
import {ComicCard} from './index';
import styled from 'styled-components';
import story1 from '../../asset/img//comic/story_ryuzozi-1.png';
import story2 from '../../asset/img//comic/story_ryuzozi-2.png';
import story3 from '../../asset/img//comic/story_ryuzozi-3.png';

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const StoryRyuzozi = () => {
    const characterList = [
        {
            img: story2,
            alt: '龍造寺兄弟',
            name: '龍造寺兄弟',
            link: '/comic/ryuzozi/story-1'
          },
          {
            img: story1,
            alt: '龍造寺家のゴッドマザー',
            name: '龍造寺家のゴッドマザー',
            link: '/comic/ryuzozi/story-2'
          },
          {
            img: story3,
            alt: '有馬ハレノブと凶暴タカノブ',
            name: '有馬ハレノブと凶暴タカノブ',
            link: '/comic/ryuzozi/story-3'
          },
      ]

      

    return(
      <div className="common__item">
<H3>龍造寺家のモノガタリ</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <ComicCard
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        link={characterItem.link}
        />
    )
})}
</Characters>

</div>
    )
}
export default StoryRyuzozi;