import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/ryuzozi/comic10_1.png';
import comic2 from '../../../asset/img/comic/ryuzozi/comic10_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story12 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>有馬ハレノブと凶暴タカノブ</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "有馬ハレノブと凶暴タカノブ"
   alt_2= "有馬ハレノブと凶暴タカノブ"
  />
  <TextArea 
   text1="当時、現在の「長崎県」を中心に勢力を持った「有馬家」"
   text2="そして当主である「有馬晴信」は隣国「竜造寺家」‥"
   text3="のちに、有馬晴信は、島津家と結託して「竜造寺隆信」を倒します。"
   data="2018/07-14"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story12;