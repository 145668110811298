import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic3.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story1 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>お猿さんと大友ヨシシゲ</H2>
  <Images 
  image_1= {comic1}
  style2= "display-none"
  style3= "display-none"
   alt_1= "お猿さんと大友ヨシシゲ"
  />
  <TextArea 
   text1="何らかと暗い噂のある大友さん。"
   text2="彼の逸話について「猿」のお話は有名です‥"
   text3="最後に「猿」は道雪により殺されてしまうらしいですが‥"
   data="2018/02-08"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
     )
}
export default Story1;