import React from 'react';
import {ContentsOtomo,ContentsShimadu,ContentsRyuzozi,ContentsOther} from './index';
import styled from 'styled-components';

const CHARACTER = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: space-around;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;

h3{
  font-size: 20em !important;
}
`;



const Character = () => {
   
    return(

      <div className="common__item">
      <div className="common__center">
    <h2>キャラクター</h2>

<ContentsOtomo />
<ContentsShimadu />
<ContentsRyuzozi />
<ContentsOther />


   <CHARACTER>

   </CHARACTER>
   </div>
   </div>

    )
}
export default Character;