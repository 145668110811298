import React from 'react';
import { StoryRyuzozi,StoryOther,StoryOtomo,StoryShimadu } from '.';

const Comic = () => {
    return(
        <div className="common__item">
        <div className="common__center">
    <h2>Comic</h2>
    <p>過去に投稿したコミックを載せています。</p>

   <StoryOtomo/>
   <StoryShimadu/>
   <StoryRyuzozi/>
   <StoryOther/>
</div>
</div>
    )
}
export default Comic;