import React from 'react';
import InfoCard from './InfoCard';
import styled from 'styled-components';

const INFO = styled.div`
 
`

const media = {
    sp: '@media(max-width: 650px)'
  }

const UL = styled.ul`
     display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    ${media.sp} {
        padding-inline-start: 0;
   }
`

const Information = () => {
    const cardList1 = [
      {
        day: '2023.11.21',
        text: 'コンテンツ追加-お待ちください',
      },
      {
        day: '2023.11.20',
        text: 'キャラクター更新「立花ムネシゲ・仙石ヒデヒサ」',
      },
      {
        day: '2022.10.09',
        text: 'comic更新「大友家」',
      },
      {
        day: '2022.09.19',
        text: 'キャラクター更新',
      },
      {
        day: '2022.01.04',
        text: '新年明けましておめてどうございます',
      },
      {
        day: '2021.10.25',
        text: 'キャラクター更新',
      },
      {
        day: '2021.05.18',
        text: 'サイト公開',
      },
      {
        day: '2021.03.03',
        text: 'YouTubeチャンネル開設',
      }
    ]
    return(
<div className="common__contents">
<h2>Information</h2>


<UL>
{cardList1.map((cardItem,workCard)=>{
    return(
    <InfoCard 
    key={workCard}
    day={cardItem.day}
    text={cardItem.text}
    />
    )
})}
</UL>
</div>
    )
}
export default Information;