import React from 'react';
import styled from 'styled-components';

const LINK = styled.div`
vertical-align: middle;
display: inline-block;
text-align: center;
border: solid 3px rgb(235, 235, 235);
border-radius: 0.5em;
padding: 1.5em 1em;
width: 7.5em;
height: auto;
background-color: #fff;
`;

const Links = () => {
    return(
<div className="common__contents">
<h2>Links</h2>
<a href="#">
<LINK>
 <img src="https://placehold.jp/70x70.png" alt="ココモノ-家紋工房"/>
 <p><small>ココモノ-家紋工房</small></p>
</LINK>
</a>
</div>
    )
}
export default Links;