import React from 'react';
import styled from 'styled-components';

const media = {
    sp: '@media(max-width: 650px)'
  }

const Texts = styled.div`
 background-color: #fff;
 border: solid 3px rgb(235,235,235);
 border-radius: 0.5em;
 width:100%;
 max-width: 50em;
 text-align: center;
 margin: 2em auto 0;
 padding: 1em;
 ${media.sp} {
    width: initial;

   }
`;

const TextArea = (props) => {

    return(
<Texts>
    <p>{props.text1}</p>
    <p>{props.text2}</p>
    <p>{props.text3}</p>
    <p>{props.data}</p>
</Texts>
    )
}
export default TextArea;