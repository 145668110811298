import React from 'react';
import styled from 'styled-components';

const BODY = styled.div`
width:100%;
max-width: 18em;
border-radius: 0.5em;
text-align: center;
background-color: #fff;
padding:1em;
border: solid 3px rgb(235, 235, 235);
transition: all ease-out .3s;
img {
    width:200px;
	height:200px;
	object-fit:cover;
	border-radius:1em;
}
h3 {
    font-weight:bold;
    font-size: 1.2em;
 }
 div{
    display: flex;
    justify-content: center;
    p {
        margin: 1em 0.5em;
    }
 p{
    text-align: left;
 }   
 }
 :hover{
 background-color:#f7f7f7;
 transition: all ease-out .3s;
 }
`;

const Link = styled.a`
 display: block;
 cursor:pointer;
 margin: 1.5em;
`;

const CardContents = styled.article`
padding-top: 1em;
`;

const CardName = styled.h4`
font-weight: bold;
font-size: 1.2em;
color:#2b2b2b !important;
`;

const CardText = styled.p`
text-align: left;
padding: 0.1em !important;
font-size: 0.9em;
color:#a5a4a4 !important;
`;

const CharacterCardOtomo = (props) => {
    return(
<>
    <Link href={props.link}>
    <BODY className="reveal">
       <img src={props.img} alt={props.alt}/>
       <CardContents>
       <CardName>{props.name}</CardName>
       </CardContents>
    </BODY> 
    </Link>
</>
    )
}
export default CharacterCardOtomo;