import React from 'react';
import {ComicCard} from './index';
import styled from 'styled-components';
import other from '../../asset/img/character-other.png';
import harenobu from '../../asset/img/character-harenobu.png';
import kanetugu from '../../asset/img/character-kanetugu.png';
import yoshihi from '../../asset/img/character-yoshihi.png';
import yoshishuke from '../../asset/img/character-yoshisuke.png';
import tanezane from '../../asset/img/character-tanezane.png';
import story1 from '../../asset/img/comic/story_shimadu-1.png';

const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const StoryShimadu = () => {
    const characterList = [
        {
            img: story1,
            alt: '島津ヨシヒサとヨシヒロ',
            name: '島津ヨシヒサとヨシヒロ',
            link: '/comic/shimazu/story-1'
          },
      ]

      

    return(
        <div className="common__item">
<H3>島津家のモノガタリ</H3>

<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <ComicCard
        key={characterCard}
        model={characterItem.model}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        link={characterItem.link}
        />
    )
})}
</Characters>

</div>
    )
}
export default StoryShimadu;