import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic17_1.png';
import comic2 from '../../../asset/img/comic/otomo/comic17_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story5 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>筑紫ヒロカドの嫁入り</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "筑紫ヒロカドの嫁入り"
   alt_2= "筑紫ヒロカドの嫁入り"
  />
  <TextArea 
   text1="島「筑紫広門(ヒロカド)」が高橋紹運に婚約を願いました。"
   text2="当時「立花道雪・高橋(紹)」が筑前、筑後に来て反大友勢を鎮圧する中。"
   text3="筑紫広門の仲間であった「秋月・高橋(鑑)」は義理親子の契りを交わし、彼の勢力維持は変化していくのでした‥"
   data="2018/12-22"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story5;