import React from 'react';
import {Hamburger} from './index'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";

const ICON = styled.div`
width:5em;
display:flex;
justify-content: space-around;
align-items: center;
color: #fff;
font-size: 2em;
`;

const Nav = () => {
    return(
<nav>
    <ICON>
    <a href="https://twitter.com/KAGAMI_HUDEHISA" target="blank"><FontAwesomeIcon icon={faTwitter} /></a>
    <a href="https://www.youtube.com/channel/UCgm4L2K3HDh71EH1azX7dLw" target="blank"><FontAwesomeIcon icon={faYoutube} /></a>
    {/* <Link to="/"><FontAwesomeIcon icon={faShoppingBasket} /></Link> */}
    </ICON>
    <Hamburger />
</nav>
    )
}
export default Nav;