import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/ryuzozi/comic7.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story11 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>龍造寺家のゴットマザー</H2>
  <Images 
  image_1= {comic1}
  style2= "display-none"
  style3= "display-none"
   alt_1= "龍造寺家のゴットマザー"
  />
  <TextArea 
   text1="「龍造寺隆信」と「鍋島直茂」の母はすごく怖いお方らしかったです、"
   text2="(さすが、隆信の母‥)"
   text3="逸話のモデルは「大友家」に迫られた際「隆信の母」の一言により勝利したお話です。"
   data="2018/05-03"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story11;