import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/other/comic5_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story19 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>筑紫ヒロカドとアキタネ</H2>
  <Images 
  image_1= {comic1}
  style2= "display-none"
  style3= "display-none"
   alt_1= "筑紫ヒロカドとアキタネ"
  />
  <TextArea 
   text="サンプルだよ"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story19;