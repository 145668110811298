import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
 margin: 2em 0;

 font-size: 1em;
 background-color: #06dce6;
 color: #fff;
 border-radius: 1em;
  :hover {
   background-color: #19bbc3;
 }
`;

const Btn = (props) => {
    return(
        <a href={props.link}>
    <Button>
     {props.text}
    </Button>
    </a>
    )
}
export default Btn;