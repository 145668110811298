import React from 'react';
import styled from 'styled-components';

const media = {
  sp: '@media(max-width: 650px)'
}

const LI = styled.li`
display: flex;
    margin: 15px auto;
    background-color: #fff;
    border-radius: 10px;
    align-items: center;
    overflow: hidden;
`

const Title = styled.div`
    display: flex;
    background-color: #3d354e;
    align-self: stretch;
    align-items: center;
    padding: 10px;
    span {
    padding: 0.7em 0.5em !important;
    font-size: 0.8em;
    line-height: 1em;
    color: #fff;
    ${media.sp} {
      padding: 0 !important;
   }
    }
`

const Text = styled.p`
font-size: 1.1em;
    line-height: 1.6em;
    padding: 0.7em 0 0.7em 1em !important;
    text-align: left;
    ${media.sp} {
      font-size: 4.5vw;
   }
`

const InfoCard = (props) => {
return(
  <LI><Title><span>{props.day}</span></Title><Text>{props.text}</Text></LI>
    )
}
export default InfoCard;