import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const LINKS = styled.div`
 display:flex;
 justify-content: center;
 a {
     font-size:2.3em;
     padding: 0 0.5em;
     color: #06dce6;
 }
`

const Sns = () => {
    return(
        <div className="common__item">
        <div className="common__center">
<h2>Sns</h2>
<LINKS>
  <a href="https://twitter.com/KAGAMI_HUDEHISA" target="blank"><FontAwesomeIcon icon={faTwitter} /></a>
  <a href="https://www.youtube.com/channel/UCgm4L2K3HDh71EH1azX7dLw" target="blank"><FontAwesomeIcon icon={faYoutube} /></a>
</LINKS>
</div>
</div>
    )
}
export default Sns;