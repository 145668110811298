import React from 'react';
import {ComicCard} from './index';
import styled from 'styled-components';
import story1 from '../../asset/img/comic/story_other-1.png';
import story2 from '../../asset/img/comic/story_other-2.png';
import story3 from '../../asset/img/comic/story_other-3.png';
import story4 from '../../asset/img/comic/story_other-4.png';
import story5 from '../../asset/img/comic/story_other-5.png';
import story6 from '../../asset/img/comic/story_other-6.png';
import story7 from '../../asset/img/comic/story_other-7.png';


const H3 = styled.h3`
font-size: 1.5em !important;

`;

const Characters = styled.div`
text-align: center;
padding-top: 1.5em;
 display:flex;
 justify-content: center;
 -webkit-flex-wrap: wrap;          /* Safari etc. */
  -ms-flex-wrap    : wrap;          /* IE10        */
  flex-wrap        : wrap;
`;

const StoryOther = () => {
    const characterList = [
        {
          img: story1,
          alt: '秋月タネザネの怒り',
          name: '秋月タネザネの怒り',
          link: '/comic/other/story-1'
         
        },
        {
            img: story2,
            alt: '筑紫ヒロカドの特技',
            name: '筑紫ヒロカドの特技',
            link: '/comic/other/story-2'
          },
          {
            img: story3,
            alt: '伊東ヨシスケの船旅',
            name: '伊東ヨシスケの船旅',
            link: '/comic/other/story-3'
          },
          {
            img: story4,
            alt: '大内ヨシナガとクリスマス',
            name: '大内ヨシナガとクリスマス',
            link: '/comic/other/story-4'
          },
          {
            img: story5,
            alt: '相良ヨシヒのグチ',
            name: '相良ヨシヒのグチ',
            link: '/comic/other/story-5'
          },
          {
            img: story6,
            alt: '筑紫ヒロカドとアキタネ',
            name: '筑紫ヒロカドとアキタネ',
            link: '/comic/other/story-6'
          }
      ]

      

    return(
      <div className="common__item">
<H3>他の家のモノガタリ</H3>
<Characters>
     {/* ootomo */}
     {characterList.map((characterItem,characterCard)=>{
    return(
        <ComicCard
        key={characterCard}
        img={characterItem.img}
        alt={characterItem.alt}
        name={characterItem.name}
        style={characterItem.style}
        text_1={characterItem.text_1}
        text_2={characterItem.text_2}
        text_3={characterItem.text_3}
        link={characterItem.link}
        />
    )
})}
</Characters>

</div>
    )
}
export default StoryOther;