import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic19_1.png';
import comic2 from '../../../asset/img/comic/otomo/comic19_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story20 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>ソウリンのトキメキと高橋s</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "ソウリンのトキメキと高橋s"
   alt_2= "ソウリンのトキメキと高橋s"
  />
  <TextArea 
   text1="高橋アキタネとシゲタネは互いに「岩屋・宝満山城」を治めた大友家の家臣です。"
   text2="第一次毛利戦後 アキタネ は謀反を起こし、なんやかんやで シゲタネ が引き継ぎました。"
   text3="ここで取り上げた「高橋s」は互いに「岩屋・宝満山城」を担当している設定です。実際は親子程年齢差があります。"
   data="2022/10-09"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story20;