import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic12_1.png';
import comic2 from '../../../asset/img/comic/otomo/comic12_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story4 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>大友ヨシシゲとイケメン家臣</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "大友ヨシシゲとイケメン家臣"
   alt_2= "大友ヨシシゲとイケメン家臣"
  />
  <TextArea 
   text1="「島津家」と「大友家」の戦いである「耳川の戦い」、この戦に「大友家」は歴史的大敗をしました。"
   text2="敗北し、この戦に参加していた「ソウリン」は敗走道中、国民から痛い目に会いました。"
   text3="命かながらの劇。道中「大友宗麟」を守った家臣として有名なのは「一万田鑑実(アキザネ)」になります"
   data="2018/07-20"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story4;