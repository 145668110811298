import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const UL = styled.div`
flex-flow: row nowrap;
    background-color:rgba(8, 243, 255, 0.814);
    position:fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    display:block;
    text-align: center;
    width: 100%;
    transition: transform 0.3s ease-in-out;

    p{
        color: #fff;
        padding: 18px 10px;
    font-size: 1.7em;
    }

ul {
    position: fixed;
    top: 50%;
    left: 50%;
}
div {
    position:relative;
    margin: 12em 0;
}

@media (max-width: 768px) {
    flex-flow: row nowrap;
    background-color:rgba(8, 243, 255, 0.814);
    position:fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    display:block;
    text-align: center;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    p{
        color: #fff;

    }
    div {
    position:relative;
    margin: 12vh;
}
}
`;

const CenterNav = ({open,setOpen}) => {

    return(
    <UL open={open}>
    <div>
    <p><Link to="/" open={open} onClick={() => setOpen(!open)}>Home</Link></p>
    <p><Link to="/about" open={open} onClick={() => setOpen(!open)}>About</Link></p>
    <p><Link to="/character" open={open} onClick={() => setOpen(!open)}>Character</Link></p>
    <p><Link to="/movie" open={open} onClick={() => setOpen(!open)}>Movie</Link></p>
    <p><Link to="/comic" open={open} onClick={() => setOpen(!open)}>Comic</Link></p>
    <p><Link to="/goods" open={open} onClick={() => setOpen(!open)}>Goods</Link></p>
    </div>
    </UL>
    )
}
export default CenterNav;