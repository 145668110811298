import React from 'react';
import styled from 'styled-components';

const Items = styled.div`
 padding: 10em 0;
`;

const Movie = () => {
    return(
        <div className="common__item">
        <div className="common__center">
        <Items>
    <h2>Movie</h2>
    <p>Sorry, Coming soon</p>
    </Items>
</div>
</div>
    )
}
export default Movie;