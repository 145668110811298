import React from 'react';
import {Images,TextArea,Page} from '../index';
import {Btn} from '../../Common/index';
import styled from 'styled-components';
import comic1 from '../../../asset/img/comic/otomo/comic15_1.png';
import comic2 from '../../../asset/img/comic/otomo/comic15_2.png';

const H2 = styled.h2`
 font-size: 2em;
 `

const Story7 = () => {
    return(
        <div className="common__item">
        <div className="common__center">
  <H2>高橋アキタネの祟り</H2>
  <Images 
  image_1= {comic1}
  image_2= {comic2}
  style3= "display-none"
   alt_1= "高橋アキタネの祟り"
   alt_2= "高橋アキタネの祟り"
  />
  <TextArea 
   text1="「髙橋鑑種(アキタネ)」は現在「北九州」を一時統治していました。"
   text2="数年後に病でなくなり、その後には「細川忠興」が入りました。"
   text3="そしかし、彼は「鑑種」の「お墓」を壊してしまい。後。祟られてしまったという逸話があります。"
   data="2018/08-12"
  />
  <Btn
   text="一覧に戻る"
   link="/comic"
  />
 </div>
 </div>
    )
}
export default Story7;