import React from 'react';
import minami from '../../asset/img/banner-minami.png';
import kita from '../../asset/img/banner-kita.png';
import other from '../../asset/img/banner-1600-over.png';
import styled from 'styled-components';

const media = {
    sp: '@media(max-width: 650px)'
  }

const Items = styled.div`
 padding: 1em 0 10rem 0;
`;

const Banners = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: row;
 img {
    width: calc(100% / 3)
 }
 ${media.sp} {
    flex-direction: column;
    img {
        width: 100%
    }
 }
`;

const ArticleContents = () => {
    return(
    <div className="common__item">
        <div className="common__center">
        <Items>
                <h2>Article Contents</h2>
                <Banners>
                    <img src={minami} alt="南九州戦国モノガタリ" />
                    <img src={kita} alt="北九州戦国モノガタリ" />
                    <img src={other} alt="1600↑戦国モノガタリ" />
                </Banners>
        </Items>
        </div>
    </div>
)
}
export default ArticleContents;