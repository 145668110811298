import React from 'react';
import {Main,Information,Links,Sns,Brand,About,ArticleContents} from './index';

const Home = () => {
    return(
<div>
    <Main />
    <div className="common__item">
  <div className="common__center">
    <Information />
    <About />
    <ArticleContents />
    </div>
    </div>
</div>
    )
}
export default Home;