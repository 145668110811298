import React from 'react';
import logo from '../../asset/img/logo.png';
import styled from 'styled-components';

const Logo = styled.img`
width: 100%;
max-width: 10em;
`;

const Brand = () => {
    return(
        <div className="common__item">
        <div className="common__center">
<Logo src={logo} alt="ココモノ"/>
</div>
</div>
    )
}
export default Brand;