import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
// import { useWindowScroll } from "react-use";
import { useScrollTo } from "react-use-window-scroll";
import styled from 'styled-components';

const media = {
  sp: '@media(max-width: 650px)'
}

const Btn = styled.button`
    border-radius: 50%;
    background-color: #06dce6;
    color: #fff;
    bottom: 6.6em;
    left: 2em;
    width: 5em;
    height: 5em;
    border: solid 2px #fff;
    position: fixed;
    svg {
      font-size: 3.2em;
    top: 0.25em;
    left: 0.45em;
    position: absolute;
    }
    ${media.sp} {
      width: 3.8em;
    height: 3.8em;
    left: 1em;
    bottom: 13vh;
    svg {
      font-size: 2.2em;
    top: 0.27em;
    left: 0.45em;
    }
   }
`;

const TopBtn = () => {
  const scrollTo = useScrollTo();
    return(
    <Btn onClick={() => scrollTo({ top: 0, left: 0, behavior: "smooth" })} id="top-btn">
      <FontAwesomeIcon icon={faAngleUp} />
    </Btn>
    )
}
export default TopBtn;