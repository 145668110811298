import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../asset/img/logo.png';
import styled from 'styled-components';

const Logo = styled.img`
width: 100%;
max-width: 10em;
`;

const Logos = styled.h1`
    padding: 0.5em 0 !important;
`;

const Header = () => {
return(
<div>
<header>
<Logos><Link to="/"><Logo src={logo} alt="ココモノ"/></Link></Logos>
</header>
</div>
    )
}
export default Header;